<template>
  <div :class="isNested ?'nested-log-table' : ''" ref="table-container">     
    <div v-if="items">
      <div>
        <b-collapse :visible="!!regeneratedLogs">
          <div class="mx-1">
            <div class="d-flex justify-content-between align-items-start">
              <div>
                <h4 class="text-secondary">Regenerated Logs: </h4>
                <p class="m-0 text-dark small" v-if="regeneratedLogs"> from: <b>{{regeneratedLogs.name}}</b></p>
              </div> 
              <div>
                <b-button variant="none" @click="regeneratedLogs = undefined">
                  <b-icon icon="x" scale="2" variant="danger"/>
                </b-button>
              </div>
            </div>

            <log-table :key="'nested-log-table'" :value="regeneratedLogs.data" name="nested-log-table" v-if="regeneratedLogs" :isNested="true"/>
          </div>
        </b-collapse>
      </div>
      <div class="d-flex align-items-end justify-content-between mb-25" align="right" v-if="!isNested">
        <div class="d-flex align-items-end">
          <div>
            <b-button class="py-25 px-50 mr-2" variant="outline-secondary" @click="$refs['import-log-modal'].show()">
              <b-icon icon="box-arrow-in-down-right" class="mr-25"/>
              Regenerate Logs
            </b-button>
          </div>
          <div>
            <span class="text-secondary small font-weight-bold">Logs only last for 45 days after being run.</span>
          </div>
        </div>
        <div class="d-flex align-items-end">
          <b-button class="py-25 px-50 mr-2" variant="outline-secondary" v-if="selectionActive" @click="$refs['log-table'].selectAllRows()">
            SELECT ALL
          </b-button>
          <span class="font-weight-bolder cursor-pointer" @click="tableVersion = 1; selectedRows=[]; selectionActive = !selectionActive">SELECTABLE</span>
          <b-form-checkbox class="selectable-toggle-checkbox" v-model="selectionActive" @input="tableVersion = 1; selectedRows=[];"></b-form-checkbox>
          <b-button class="ml-1 py-25 square" size="sm" variant="none" @click="$emit('refreshLogs')">
            REFRESH LOGS
          </b-button>
        </div>
      </div>
    </div> 
    <div v-else class="d-flex justify-content-between">
      <b-skeleton width="200px" height="20px"/>
      <b-skeleton width="150px" height="20px"/>
    </div>

    <div v-if="items" class="position-relative">
      <b-table
        :id="getID('table')"
        ref="log-table"
        :key="'log-table-'+tableVersion"
        hover
        borderless
        responsive
        select-mode="multi"
        selected-variant="none"
        :selectable="selectionActive"
         :items="items"
        :fields="fields"
        show-empty
        :empty-filtered-text="$t('common.terms.no_records_matching')"
        :empty-text="$t('common.terms.no_records_show')"
        @row-selected="(r)=>{selectedRows = r}"
        @row-clicked="rowClicked"
      >
        <template #cell(token)="data">{{ data.item._source.token }}</template>
        <template #cell(origin)="data">
          <div class="d-flex lower-opacity">
            <div class="log-custom-checkbox" :class="data.rowSelected ? 'checked' : ''" v-if="selectionActive">
              <b-icon icon="check" variant="success" scale="1.5" shift-h="1.5" shift-v="1"/>
            </div>
            <b-badge variant="light" class="text-black" v-if="data.item._source.origin.type == 'tester'">
              <b-icon icon="terminal-fill" scale="1.2" class="mr-25"/>
              Tester 
            </b-badge> 
            <b-badge variant="purple" class="text-black" v-else-if="data.item._source.origin.type == 'webhook'">
              <b-icon icon="link-45deg" scale="1.2" class="mr-25"/>
              Webhook
            </b-badge>
            <b-badge variant="info" class="text-black" v-else-if="data.item._source.origin.type == 'scheduler'">
              <b-icon icon="clock-fill" scale="1.2" class="mr-25"/>
              Scheduler 
            </b-badge>
            <b-badge variant="warning" class="text-black" v-else-if="data.item._source.origin.type == 'sync'">
              <b-icon icon="hourglass-split" scale="1.2" class="mr-25"/>
              Sync 
            </b-badge>
            <b-badge variant="favorite" class="text-black" v-else-if="data.item._source.origin.type == 'async'">
              <b-icon icon="hourglass" scale="1.2" class="mr-25"/>
              Async 
            </b-badge>
            <b-badge variant="secondary" class="text-black" v-else>
              <b-icon icon="square-fill" scale="1.2" class="mr-25"/>
              <span class="text-capitalize text-black">{{data.item._source.origin.type}}</span>
            </b-badge>
          </div>
        </template>

        <template #cell(_source.origin.event)="data">
        
          <h5 class="m-0 text-secondary d-inline"><b>{{data.item._source.origin.event}}</b></h5> 

        </template>
        <template #cell(_source.elapsed_time)="data">
          <b-badge :variant="data.item._source.success ? 'light-success' : 'light-danger'" class="text-uppercase" v-b-tooltip.hover :title="data.item._source.success ? 'Run Succeeded' : 'Run Failed'">
            <b-icon scale="1.75" :icon="data.item._source.success ? 'check' : 'x'"/>
            <!-- {{ data.item._source.success ? 'Success' : 'Failed' }} -->
          </b-badge>
          <b-badge variant="light-info" class="ml-25"  v-b-tooltip.hover title="Execution time">
            <b-icon icon="clock" scale="1.2" class="mr-25"/>
            {{ (data.item._source.elapsed_time / (1000 * 1000)).toFixed(3) }}s
          </b-badge>
        </template>
        <template #cell(_source.created_at)="data">{{ formatDate(data.item._source.created_at) }}</template>
        <template #cell(actions)="data">
          <div align="right">
            <b-button
              size="sm"
              class="px-2"
              variant="outline-info"
              @click="showDetails(data.item)"
              >
                <b-icon icon="info-circle"/> INFO
            </b-button>
            <b-button
              v-if="!isNested"
              size="sm"
              class="ml-2"
              :variant="data.item._source.origin.type == 'tester' ? 'outline-secondary' : 'outline-success'"
              :title="data.item._source.origin.type == 'tester' ? 'Tests cannot be re-run' : ''"
              @click="showConfirmRerun(data.item)"
              :disabled="data.item._source.origin.type == 'tester'"
            >
                <b-icon icon="play-fill" scale="1" class=""/> RE-RUN
            </b-button>
          </div>
        </template>

        <template #head(actions)>
          <div class="d-flex justify-content-between">
            <div>actions</div>
          </div>
        </template>

      </b-table>
      <div class="selected-row-actions-container">
        <b-collapse :visible="selectedRows.length>0">
          <div class="selected-row-actions">
            <b-button class="mr-75  py-50"  variant="favorite" @click="promptExport()">
              <b-icon icon="file-earmark-arrow-down" variant="black" class="mr-25"/>
              <span class="text-black">Export as File <span class="small">(CSV)</span></span>
            </b-button>
            <b-button class="p-50 "  variant="success" @click="runningMultiples=true; $refs['confirm-re-run-modal'].show()">
              <b-icon icon="play-fill" variant="black" class="mr-25"/>
              <span class="text-black">Re-Run Selected</span>
            </b-button>
          </div>
        </b-collapse>
      </div>
    </div>

    <div v-else>
      <b-skeleton-table
        :rows="5"
        :columns="4"
        :table-props="{ bordered: true, striped: true }"
      />
    </div>

    <b-modal
      hide-footer
      size="xl"
      title="Log detail"
      body-class="terminal-background-color"
      scrollable
      ref="log-detail-current"
    >
      <b-tabs lazy v-if="currentItem">
        <b-tab active title="Log info" v-if="currentRun && currentItem._source.origin.type != 'tester'">

          <div align="right" class="mb-2" v-if="!isNested">
            <b-button class="py-25 px-50 mr-1" variant="favorite" @click="promptExport(currentItem)">
              <b-icon icon="file-earmark-arrow-down"/>
              Export to CSV
            </b-button> 
            <b-button class="py-25 px-50" variant="success" @click="showConfirmRerun(currentItem)" v-if="currentItem._source.origin.type!= 'tester'">
              <b-icon icon="play-fill" variant="black"/>
              <span class="text-black">Re-Run Log</span>
            </b-button> 
          </div>

          <run-log-info :run="currentRun"/>

        </b-tab>
        <b-tab title="Raw Log">
          
          <json-viewer
            :value="currentLog"
            :expand-depth="15"
            :copyable="true"
            :show-array-index="false"
            :show-double-quotes="true"
            theme="custom-jv-dark"
            class="border rounded"
          />

        </b-tab>
      
      </b-tabs>
    </b-modal>

    <b-modal
      hide-footer
      size="xl"
      title="Log detail"
      scrollable
      ref="log-detail-v1"
    >
      <b-tabs lazy v-if="currentItem">
        <b-tab active title="Log info" v-if="currentRun && currentItem._source.origin.type != 'tester'">
          <div align="right" class="mb-50" v-if="!isNested">
            <b-button class="py-25 px-50 mr-1" variant="favorite" @click="promptExport(currentItem)">
              <b-icon icon="file-earmark-arrow-down"/>
              Export to CSV
            </b-button> 
            <b-button class="py-25 px-50 mr-50" variant="success" @click="showConfirmRerun(currentItem)" v-if="currentItem._source.origin.type!= 'tester'">
              <b-icon icon="play-fill"/>
              Re-Run Log
            </b-button> 
          </div>
          <run-log 
            :run="currentRun"
          />
        </b-tab>
        <b-tab title="Raw Log">
          <json-editor 
            :id="getID('log-raw-json')" 
            :key="getID('log-raw-json')"  

            v-model="currentLog" 
            :modes="[ 'code', 'tree', 'preview' ]" 
            :height="700" 
          />
        </b-tab>
      
      </b-tabs>
    </b-modal>



    <b-modal
      ref="export-log-modal"
      size="lg"
      ok-only
      ok-variant="favorite"
      title="Export Log File"
      centered
      @ok="downloadExportLogsAsCSV()"
    > 

      <div class="exporting-logs-container mx-1">
        <span class="font-weight-bolder ml-50">Exporting:</span>
        <div class="">
          <div v-for="(log, idx) in logExport.data" class="">
            <b-icon icon="circle-fill" scale="0.4" shift-v="-2" shift-h="" class="ml-1" variant="secondary"/>
            <span class="small text-secondary mr-1">
              <span class="mr-25 text-dark">({{log._source.origin.type}})</span>
              {{log._source.token}}
            </span>
            <span class="small"><span class="text-dark">{{getDate(log._source.created_at)}}</span></span>
          </div>
        </div>
      </div>



      

      <div class="mt-1">
      <span class="small font-weight-bolder text-dark">File name:</span>
        <b-form-input v-model="logExport.name" class=""></b-form-input>
      </div>


      <template #modal-ok>
        <div>
          <b-icon icon="download" class="mr-50"/>
          Download Export <span class="small">(CSV)</span>
        </div>
      </template>
    </b-modal>

    <b-modal
      ref="import-log-modal"
      size="md"
      ok-only
      ok-variant="success"
      title="Import Log File"
      centered
      :ok-disabled="!importingFile"
      @ok="importLogs()"
    > 
      <p class="small text-secondary">If you have previously exported Logs as a CSV File you may use it to temporarily restore them here.</p>
      
      <b-form-file v-model="importingFile" accept=".csv"></b-form-file>
      
      <template #modal-ok>
        <div class="text-black">
          <b-icon icon="layout-text-sidebar-reverse" scale="0.95" class="mr-50"/>
          Regenerate Logs
        </div>
      </template>
    </b-modal>
    
    <b-modal
      ref="confirm-re-run-modal"
      title="Confirm Re-Run"
      centered
      @ok="reExecuteLog"
      @hide="(e)=>{if (modalLoading) e.preventDefault()}"
      @hidden="runningMultiples = false"
    > 
      <h5 v-if="!runningMultiples">Do you wish to re-execute this Log?</h5>
      <h5 v-else>Do you wish to re-execute these Logs?</h5>
      <p class="text-dark small" v-if="!runningMultiples">It'll be executed on the same circumstances of when it was first run.</p>
      <p class="text-dark small" v-else>They'll be executed on the same circumstances of when they were first run.</p>

      <p class="text-secondary small mt-50" v-if="runningMultiples">*Any Test Runs selected will be ignored.</p>

      <template #modal-ok>
        <div>
          <template v-if="!modalLoading">
            <b-icon icon="skip-backward-circle" class="mr-25"/> Re-Run Log<span v-if="runningMultiples">s</span>
          </template>
          <template v-else>
            <b-spinner small/>
          </template>
        </div>
      </template>
    </b-modal>

    <div class="search-more text-center p-2">
      <b-spinner
        v-if="searchingNextPage"
        type="grow"
        variant="primary"
        :label="$t('common.terms.loading')"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BFormSelect,
  BFormInput,
  BTable,
  BContainer,
  BSkeletonTable,
  BBadge,
  BPagination,
  BSpinner,
  VBTooltip,
  BAvatar,
  BModal,
  BFormCheckbox,
  BCollapse,
  BTabs,
  BTab,
  BFormFile,
  BSkeleton,
  BIconstack,
} from "bootstrap-vue";
import { v4 as uuidv4 } from "uuid";
import { makeToast } from "@/layouts/components/Popups.js";
import moment from "moment";
import flatPickr from 'vue-flatpickr-component'
import Run from '../Terminal/TerminalTabs/Run/class/run';
import RunLog from '../Terminal/TerminalTabs/Run/RunLog.vue';
import JsonEditor from "@/layouts/components/JsonEditor/JsonEditor.vue";
import { errorToast, successToast } from '@/custom/class/FunctionClasses/CommonToasts';
import RunLogInfo from "@/layouts/components/Terminal/TerminalTabs/Run/Refactored/RunLogInfo.vue"
import JsonViewer from "vue-json-viewer";

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BButton,
    BFormGroup,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormInput,
    BContainer,
    BSkeletonTable,
    BBadge,
    BPagination,
    BSpinner,
    BAvatar,
    flatPickr,
    RunLog,
    BModal,
    JsonEditor,
    BFormCheckbox,
    BCollapse,
    BTabs,
    BTab,
    BFormFile,
    BIconstack,
    BSkeleton,
    RunLogInfo,
    JsonViewer,
    LogTable: () => import("@/layouts/components/Dashboard/LogTable.vue"),
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    scrollContainer: {
      type: String,
      default: ".modal"
    },
    primaryColumn: {
      type: String,
      default: 'token'
    },
    scroll_id: {
      type: String,
      default: undefined
    },
    value: {
      type: Array,
      default: undefined
    },
    isNested: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      currentItem: undefined,
      currentLog: {},
      fields: [
        {
          key: this.primaryColumn,
          label: this.$t(`dashboard.general.log_table.primary_column.${this.primaryColumn}`),
          sortable: true,
          thStyle: "width: 150px"
        },
        {
          key: "_source.origin.event",
          label: "Event Triggered",
          sortable: true,
        },
        {
          key: "_source.elapsed_time",
          label: "Result",
          sortable: true,
        },
        {
          key: "_source.created_at",
          label: "Executed at",
          sortable: true,
          thStyle: "width: 300px"
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          thStyle: "width: 300px"
        },
      ],
      uuidMap: {},
      searchingNextPage: false,
      tableVersion: 0,
      selectedRows: [],
      selectionActive: false,
      logExport:{
        data: undefined,
        name: 'exported_logs'
      },
      importingFile: undefined,
      rerunLog: undefined,
      modalLoading: false,
      runningMultiples: false,
      regeneratedLogs: undefined, 
      previsouslySelected: []
    }
  },
  computed: {
    items: {
      get() {
        return this.value;
      },  
      set(value) {
        this.$emit('input', value);
      }
    },
    transmissionID() {
      return this.$route.params.transmissionID;
    },
    currentRun() {
      if(this.currentItem === undefined) {
        return undefined;
      }
      return this.getRun(this.currentItem);
    }
  },
  mounted() {    
    this.scroll();
    this.$emit('created')
  },
  methods: {
    scrollIntoView(params){
      this.$refs['table-container'].scrollIntoView(params)
    },
    goNextPage() {
      this.$emit('goNextPage')
      if (this.previsouslySelected.length == 0){
        this.previsouslySelected = structuredClone(this.selectedRows)
      }
    },
    sustainSelected(){
      let prevSel = structuredClone(this.previsouslySelected)
      
      let rIdx = 0
      const items = structuredClone(this.items)
      items.forEach(row => {
        let idx = prevSel.findIndex(el=> el._id == row._id)
        if (idx > -1){
          this.$refs['log-table'].selectRow(rIdx)
        }
        rIdx = rIdx + 1
      });
      
      this.previsouslySelected = []
    },
    isScrolledIntoView(el) {
      let rect = el.getBoundingClientRect();
      let elemTop = rect.top;
      let elemBottom = rect.bottom;

      let isVisible = elemTop < window.innerHeight && elemBottom >= 0;

      return isVisible;
    },
    scroll() {
      document.querySelector(this.scrollContainer).onscroll = () => {
        let scrolledTo = document.querySelector(".search-more");

        if (
          scrolledTo &&
          this.isScrolledIntoView(scrolledTo) &&
          !this.searchingNextPage &&
          this.scroll_id
        ) {
          this.goNextPage();
        }
      };
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    formatDate(value) {
      if (!value) return null
      let date = new Date(value)
      return moment(date).format(this.$t('scheduler.view.moment_date_format'));
    },
    refreshItems() {
      this.item = undefined;
      this.init();
    },
    showDetails(item) {
      this.currentItem = item;
      this.currentLog = item._source;
      
      let version = (item._source.version).toLowerCase()
      let ref = 'current'

      switch (true) {
        case version.startsWith('v1'):
          ref = 'v1'
          break;
        default:
          ref = 'current'
          break;
      }

      ref = 'log-detail-' + ref
      this.$refs[ref].show()
    },
    getRun(item) {
      return new Run({
          index: 0,
          transmission_id: this.transmissionID,
          token: item._source.token,
          origin: item._source.origin,
          success: item._source.success,
          level: item._source.level,
          message: item._source.message,
          event: item._source.origin.event || undefined,
          sender: item._source.origin.service || undefined,
          webhook: item._source.origin.webhook || undefined,
          scheduler: item._source.origin.scheduler || undefined,
          json_return: {
            logs: {
              '$IN': item._source.data['$IN'],
              '$OUT': item._source.data['$OUT'],
            },
            info: item._source.data.info,
          },
          created_at: item._source.created_at,
          time: item._source.elapsed_time,
          script_id: item._source.script_id,
        });
    },
    onCopy() {
        makeToast({
          title: this.$t(`scheduler.modal.script_viewer.copy.title`),
          text: this.$t(`scheduler.modal.script_viewer.copy.message`),
          variant: "success",
          icon: "FileTextIcon",
        })
    },
    toggleRowSelect(itm, index){
      this.selectedRows[index] = !this.selectedRows[index]
    },
    rowClicked(itm,index){
      if (this.selectionActive){
        return
      }
      this.showDetails(itm)
    },
    getDate(itm){
      return moment(new Date(itm)).format('lll')
    },
    promptExport(itm = undefined){
      if (itm){
        this.logExport.data = [itm]
      } else {
        this.logExport.data = this.selectedRows
      }
      this.$refs['export-log-modal'].show()
    },
    showConfirmRerun(itm){
      this.rerunLog = itm
      this.$refs['confirm-re-run-modal'].show()
    },
    reExecuteLog(e){
      let tokens = [] 
      e.preventDefault();
      let multiples = structuredClone(this.runningMultiples);
      if (!multiples){
        tokens = [this.rerunLog._source.token]
      } else {
        this.selectedRows.forEach(row => {
          if (!row._source.origin.type == 'tester'){
            tokens.push(row._source.token)
          }
        });
      }
      this.modalLoading = true;
      this.$store.dispatch('reRunLog', {transmissionID: this.transmissionID, tokens: tokens})
        .then((resp)=>{
          this.modalLoading = false
          successToast({text: "Log Successfully re-run"})
          this.$nextTick(()=>{
            this.$refs['confirm-re-run-modal'].hide()
          })
        })
        .catch((err)=>{
          console.error(err)
          errorToast({text: "Something went wrong while re-running the log"})
        })
        .finally(()=>{
          this.modalLoading = false
          if (multiples){
            this.$refs['log-table'].clearSelected()
            this.selectedRows = []
          }
        })
    },
    downloadExportLogsAsCSV(){
      let selectedLogs = structuredClone(this.logExport.data)

      let csvData = selectedLogs.map(({
        _index, _id, _source, sort
      }) => ({
        index: _index,
        id: _id,
        token: _source.token,
        transmission: _source.transmission,
        origin_type: _source.origin.type,
        origin_service: _source.origin.service,
        origin_event: _source.origin.event,
        script_id: _source.script_id,
        created_at: _source.created_at,
        run_time: _source.elapsed_time,
        run_status: _source.success ? 'Success' : 'Error',
        run_level: _source.level,
        message: _source.message,
        scheduler_identifier: _source.origin.scheduler,
        webhook_identifier: _source.origin.webhook,
        data: JSON.stringify(_source.data),
      }))

      function convertToCSV(data) {
        const csvRows = [];
        const headers = Object.keys(data[0]);
    
        csvRows.push(headers.join(','));
    
        for (const row of data) {
          const values = headers.map(header => {
            let val = row[header]
            if (!(typeof val === 'string') || !(val instanceof String)){
              val = String(val)
            }
            val = val.replaceAll('"' , '""')
          
            return `"${val}"`;
          });
  
          csvRows.push(values.join(','));
        }
        return csvRows.join('\n');
      };
      let r = convertToCSV(csvData)
      let filename = this.logExport.name + '.csv'

      const blob = new Blob([r], {type: 'csv'});
      if(window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, filename);
      }
      else{
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;        
        document.body.appendChild(elem);
        elem.click();        
        document.body.removeChild(elem);
      }
      makeToast({
        title: "Downloading...",
        text: `${filename} started downloading`,
        variant: "secondary",
        icon: "DownloadIcon",
      })
      this.selectionActive = false
      this.selectedRows = []
      setTimeout(() => {
        this.logExport = {
          data: undefined,
          name: 'exported_logs'
        }
      }, 300);

    },
    importLogs(){
      let csv = structuredClone(this.importingFile)
      
      
      let name = csv.name      

      let reader = new FileReader()
      
      reader.onload = e => {
        let text = e.target.result;
        this.digestImported(text, name)
      };
      reader.readAsText(csv)  
      this.importingFile = undefined
    },
    digestImported(raw, name){
      let resultJson;
      

      { //  TURNS CSV INTO AN OBJECT ===========
        // raw = raw.replaceAll('\"' , "")  
        let csv = raw.split('\n')
        let header = csv.shift().split(',')
        let r = []

        csv.forEach(row => {
          let split = row.split(',')
          let json = {}
          let idx = 0
          header.forEach(k => {
            // json[k] = split[idx]
            if (k == 'data'){
              let t = split.slice(idx, split.length)         
              t = t.join(',')
              t = t.replaceAll('\"\"' , '"')
              t = t.slice(1,-1);
              json[k] = JSON.parse(t)
            } else {
              json[k] = split[idx].replaceAll(`\"` , ``)
            }
            idx = idx + 1
          });
          r.push(json)
        })
        resultJson = r
      } //======================================

      resultJson = resultJson.map(({
        index,id,token,transmission,origin_type,origin_service,origin_event,created_at,run_time,run_status,run_level,message,scheduler_identifier,webhook_identifier,data,
      }) => ({
        _index: index,
        _id: id,
        _score: null, //may not use
        _source:{
          token: token,
          transmission: transmission,
          origin: {
            type: origin_type,
            service: origin_service,
            event: origin_event,
            scheduler: scheduler_identifier,
            webhook: webhook_identifier,
          },
          level: run_level,
          success: run_status == "Success" ? true : false,
          elapsed_time: run_time,
          tranferred_data: null, //may not use
          created_at: parseInt(created_at),
          message: message,
          version: null,
          data: (data),
          script_id: null,
        },
        sort: null, //may not use
      }))      
      
      this.regeneratedLogs = {
        data: resultJson,
        name: name
      }
    }
  }
}
</script>

<style lang="scss">

@import "@/assets/scss/test-terminal.scss";
@import "@/assets/scss/json-viewer-themes.scss";

.terminal-background-color{
  background-color: $terminal-bg !important; 
} 


</style>


<style lang="scss" scoped>
.lower-opacity{
  opacity: 0.65;
}

.log-custom-checkbox{
  width: 21px;
  height: 21px;
  border-radius: 5px;
  border: 2px solid var(--secondary);
  margin-right: 8px;
  
  &.checked{
    border: 2px solid rgb(70, 160, 110);
    background-color: rgba(40, 199, 111, 0.3);
    *{
    visibility: visible;
    }
  }
  *{
    visibility: hidden;
  }
}
.selectable-toggle-checkbox{
  scale: 1.2;
  margin-left: 10px;
  z-index: 1;
}

.selected-row-actions-container{
    bottom: 0;
    margin-right: 0;
    position: sticky;

  .selected-row-actions{
    margin-left: auto;
    width: fit-content;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 8px 8px 8px;
    border-radius: 10px 0 0 0;
    border: 2px solid rgba(0, 0, 0, 0.2);
  }
}

.exporting-logs-container{
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5px;
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  margin-top: 5px;
  max-height: 300px;
  overflow-y: auto;
}

.nested-log-table{
    
}
</style>